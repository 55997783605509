import React from 'react'
import { Box, Link, Tooltip, Typography } from '@material-ui/core'
import { Entity } from '@backstage/catalog-model'
import SlackIcon from './SlackIcon'

const SlackChannelField = ({ entity }: { entity: Entity }) => {
  const annotations = entity?.metadata.annotations
  const slackChannelUrl = annotations?.['sennder.com/slack-channel-url']
  const slackChannelName = entity?.metadata.title

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Slack Channel">
        <Box>
          <SlackIcon />
        </Box>
      </Tooltip>

      <Box sx={{ ml: 3 }}>
        {slackChannelUrl ? (
          <Typography variant="body1">
            <Link href={slackChannelUrl} target="_blank" underline="hover">
              {slackChannelName}
            </Link>
          </Typography>
        ) : (
          <Typography variant="body1">N/A</Typography>
        )}

        <Typography variant="body2" color="textSecondary">
          Slack Channel
        </Typography>
      </Box>
    </Box>
  )
}

export default SlackChannelField
